<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_1.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Step 1 of 10</span>
    </div>

    <div class="flex h-screen text-center" style="height:80vh;">

      <div class="m-auto pb-10" style="max-width: 800px;">

        <div class="mb-12 relative">
          <span>This will take approximately 20 minutes to complete</span>
        </div>

        <h1 class="mb-9">Welcome to your Relatus Profile!</h1>

        <img
          class="m-auto mb-5"
          src="@/assets/images/undraw/welcome.svg"
        >

        <h4 class="text text-2xl mb-6">Next step: enhancing your profile</h4>

        <p class="mb-6 text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Adipiscing enim eu turpis egestas pretium aenean pharetra magna ac. Vel pharetra vel turpis nunc eget. Felis eget nunc lobortis mattis aliquam faucibus purus in. Malesuada fames ac turpis egestas sed tempus. Purus gravida quis blandit turpis cursus in hac. </p>

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'registration.v2'}"
            class="btn btn-primary-outline btn-large align-self-center me-auto mr-10"
          >
            Finish Later
          </router-link>

          <router-link
            :to="{name: 'v2.profile.basic'}"
            class="btn btn-primary btn-large align-self-center me-auto"
          >
            Onwards
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Welcome',
  mounted() {
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
</style>
